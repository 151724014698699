import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import {withCookies} from "react-cookie";
import {Row, Col, Button, TabPane} from "reactstrap";
import {LinearProgress} from "@mui/material";

class CheckoutGateway extends Component {
  constructor(props) {
    super(props);
    this.handleGuestCheckout = this.handleGuestCheckout.bind(this)
    this.handleNav = this.handleNav.bind(this)

    this.state = {
    }
  }


  componentDidMount() {

  }


  componentDidUpdate(prevProps) {

    let {mergeCartSuccess, history} = this.props;

    if (mergeCartSuccess && prevProps.mergeCartSuccess !== mergeCartSuccess) {
      history.push("/guest-checkout")
    }

  }

  handleGuestCheckout () {
    const { cartProducts } = this.props;
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.props.mergeCart && this.props.mergeCart({
      itemsToAdd: JSON.stringify(cartProducts)
    })
  }

  handleNav(e) {
    let { history} = this.props;
    history.push("/login")
  }


  render() {

    const { mergeCartLoader } = this.props;

    return (
      <div>
        <Helmet>
          <meta name="description"
                content="Sign in OR Checkout as guest " />
          <title>Doorstep Booze delivery what is going on</title>
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                {mergeCartLoader ?
                  <LinearProgress />
                  :
                  null
                }
                <div className="static-info-page" style={{padding: "40px 8px 40px 8px"}}>
                  <div style={{padding: "8px 0px 24px 0px", fontWeight: "bold"}}>
                    <div> HOW WOULD YOU LIKE TO CHECKOUT?</div>
                  </div>
                  <hr/>
                  <Row>
                    <Col
                      sm={12} md={6} lg={6}
                      style={{padding: "8px 0px 32px 0px", fontWeight: "bold"}}
                    >
                      <div>
                        <div> New customer?</div>
                        <Button
                          style={{marginTop: "30px", backgroundColor: "black"}}
                          className="filter-btn"
                          id="register"
                          size={"sm"}
                          block={false}
                          onClick={this.handleNav}
                        >Register
                        </Button>
                        <div
                          className="form-separator"
                          style={{width: "60%"}}
                        >
                          <span>OR</span>
                        </div>
                        <Button
                          style={{marginTop: "30px", backgroundColor: "black"}}
                          className="filter-btn"
                          id="signIn"
                          size={"sm"}
                          block={false}
                          onClick={this.handleGuestCheckout}
                        >Checkout as guest
                        </Button>
                      </div>
                    </Col>
                    <Col
                      sm={12} md={6} lg={6}
                      style={{padding: "8px 0px 32px 0px", fontWeight: "bold"}}
                    >
                      <div>
                        <div> Returning customer? </div>
                        <Button
                          style={{marginTop: "30px",  backgroundColor: "black"}}
                          className="filter-btn"
                          id="signIn"
                          size={"sm"}
                          block={false}
                          onClick={this.handleNav}
                        >Sign in
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default compose(withCookies, withRouter)(CheckoutGateway);
