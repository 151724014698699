import React from 'react'
import {Link} from 'react-router-dom';

const Activate = (props) => {
  return (
    <div className="row">
        <div className="offset-1 col-10 offset-lg-1 col-lg-10">
            <div className="container jumbotron text-center">
                <h5 style={{color:"#000000", fontWeight: "bold", marginBottom:"32px"}}>Account Created</h5>
                <div style={{color:"#000000"}}>Verify your email address </div>
                <div style={{color:"#000000"}}>to access all of DOORSTEP BOOZE features</div>

                <div style={{marginTop:"32px"}}>
                    <i className="far fa-envelope" style={{fontSize:"65px"}}></i>
                </div>  

                <div style={{marginTop:"16px"}} className="page-information">Verification email was sent to <br/>
                <p style={{textDecoration:"underline" ,color:"#6fa8dd"}}>
                    {props.email}
                </p><br/>
                Open the email and click the activation link. It may take up to 15 minutes to receive the email
                </div>
                <p style={{fontWeight: "700", textAlign: "center"}}>
                  Tip: Check your spam folder in case the email was incorrectly identified.
                </p>


                <div className="text-center justify-content-center">
                  <div> Didn't receive the email? </div>
                  <div><a href="/resend-activation-link" className="a-links"> Resend Email </a></div>

                  <div style={{margin:"32px 0px 32px 0px"}}>
                    <Link
                      to="/"
                      className="btn btn-primary">
                      Continue
                    </Link>
                  </div>
                </div>
            </div>
        </div>
   </div>
  )
}

export default Activate
