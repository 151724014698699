// "beer", "cider", "spirits", "non-alcoholic", "wine", "tobacco"
export const prodLandingData = [
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "Divan Beds",
    "search_q": "beer",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/products/divan-beds.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "Ottoman Beds",
    "search_q": "cider",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/products/ottoman-brown.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "Headboards",
    "search_q": "spirits",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/products/headboard.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "Sofa Beds",
    "search_q": "wine",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/products/sofa-beds.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  ]

export const sofaLandingData = [
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "Recliner Sofas",
    "search_q": "non-alcoholic",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/products/recliner-sofa.jpeg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "Fabric Sofas",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "search_q": "tobacco",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/products/fabric-sofas.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "Corner Sofas",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "search_q": "offers",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/products/corner-sofa.png"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "Dining",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "search_q": "offers",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/products/dining.jpeg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  }
]