export const dev_landing_data = [{'title': 'Mercedes C Class', 'price': 4500,
  'remote_image': 'https://i.ebayimg.com/images/g/6Q8AAOSw9she4Qwk/s-l500.jpg',
  'fuel_type': 'Petrol', 'id': '/partner-one-search/mercedes%20c%20class/1500/75000/1/b'},
  {'title': 'Audi Q7 Quattro', 'price': 3950,
    'remote_image': 'https://i.ebayimg.com/images/g/YNoAAOSwa~9exDwa/s-l500.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search/audi%20q7/3000/75000/1/b'},
  {'title': 'VW Amarok Highline 1.6 diesel zetec  2008', 'price': 6500,
    'remote_image': 'https://i.ebayimg.com/images/g/DSYAAOSwqQpe0pDo/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search/VW%20Amarok%20Highline/3000/75000/1/b'},
  {'title': 'BMW X6 Sport 163BHP Blue Sunroof', 'price': 11950,
    'remote_image': 'https://i.ebayimg.com/images/g/JNwAAOSwh9dez0A6/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search/bmw%20x6/8000/75000/1/b'},
  {'title': 'Range Rover Vogue 2014 model', 'price': 9895,
    'remote_image': 'https://i.ebayimg.com/images/g/NroAAOSwIzpeuVkc/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search/Range%20Rover%20Vogue/5000/75000/1/b'},
  {'title': 'Ford Ranger Wildtrak 97000 miles', 'price': 12495,
    'remote_image': 'https://i.ebayimg.com/images/g/li0AAOSwriZe4nZd/s-l500.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search/ford%20ranger/1000/75000/1/b'}];

export const accorladesData = [{'title': 'By Video', 'price': 4500,
  'remote_image': require('../assets/single-elements/icons/icons8-facetime-64.png'),
  'fuel_type': 'Petrol', 'id': '/partner-one-search/mercedes%20c%20class/1500/75000/1/b'},
  {'title': 'Free Delivery', 'price': 3950,
    'remote_image': require('../assets/single-elements/icons/icons8-delivery-100.png'),
    'fuel_type': 'Petrol', 'id': '/partner-one-search/audi%20q7/3000/75000/1/b'},
  {'title': 'Quality Guarantee', 'price': 3950,
    'remote_image': require('../assets/single-elements/icons/icons8-guarantee-100.png'),
    'fuel_type': 'Petrol', 'id': '/partner-one-search/audi%20q7/3000/75000/1/b'}
]

export const homeTechData = [{'title': 'Games console', 'price': 100,
  'remote_image': 'https://i.ebayimg.com/images/g/jrQAAOSwpvZaDCup/s-l500.jpg',
  'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/console/'},
  {'title': 'Apple MacBook Pro', 'price': 250,
    'remote_image': 'https://i.ebayimg.com/images/g/LAoAAOSwhh9c0aHn/s-l500.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/macbook'},
  {'title': 'Laptops Lenovo Dell', 'price': 95,
    'remote_image': 'https://i.ebayimg.com/images/g/oW0AAOSw0ptbZGPc/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/laptops'},
  {'title': 'Desktop Towers Dual ', 'price': 100,
    'remote_image': 'https://i.ebayimg.com/images/g/Uj4AAOSwFSxaM--k/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/desktop'},
  {'title': 'Apple iMac Machines', 'price': 75,
    'remote_image': 'https://i.ebayimg.com/images/g/bMMAAOSw0lldCnMm/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/mac'},
  {'title': 'Tablets Quad Core', 'price': 35,
    'remote_image': 'https://i.ebayimg.com/images/g/wRcAAOSwOX1eSnvt/s-l500.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/tablet'}];

export const homeCommercialData = [{'title': 'Tractors & Farm', 'price': 5000,
  'remote_image': 'https://i.ebayimg.com/images/g/BScAAOSwuh1e4gXL/s-l500.jpg',
  'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/com-agric'},
  {'title': 'Trucks & Lorries', 'price': 4000,
    'remote_image': 'https://i.ebayimg.com/images/g/S~kAAOSw40Ve12z8/s-l500.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/com-lorries'},
  {'title': 'Bus & Coach', 'price': 1000,
    'remote_image': 'https://i.ebayimg.com/images/g/qeEAAOSwlFZewXsS/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/com-bus'},
  {'title': 'Trailers & Transporters', 'price': 5000,
    'remote_image': 'https://i.ebayimg.com/images/g/49gAAOSw6ohe0MYu/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/com-trailers'},
  {'title': 'Vans Pick up', 'price': 1000,
    'remote_image': 'https://i.ebayimg.com/images/g/pIwAAOSw3Rxe4hEf/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/mac'},
  {'title': 'Other & Commercial', 'price': 700,
    'remote_image': 'https://i.ebayimg.com/images/g/w~wAAOSwuDte32yb/s-l500.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/tablet'}];
