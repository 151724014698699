import {
  GET_VEHICLE_MAKE_COUNT,
  GET_VEHICLE_MAKE_COUNT_SUCCESS,
  GET_VEHICLE_MAKE_COUNT_FAILURE,
  SEARCH_VEHICLE,
  SEARCH_VEHICLE_SUCCESS,
  SEARCH_VEHICLE_FAILURE,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_SPECIFICATIONS,
  GET_SPECIFICATIONS_SUCCESS,
  GET_SPECIFICATIONS_FAILURE,
  GET_PRICE,
  GET_PRICE_SUCCESS,
  GET_PRICE_FAILURE,
  ADD_PRODUCT_TO_BASKET,
  ADD_PRODUCT_TO_BASKET_SUCCESS,
  ADD_PRODUCT_TO_BASKET_FAILURE,
  GET_PROMOTIONAL_API_DATA,
  GET_PROMOTIONAL_API_DATA_SUCCESS,
  GET_PROMOTIONAL_API_DATA_FAILURE,
  GET_VEHICLE,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAILED, GET_STOCK_RECORD, GET_STOCK_RECORD_SUCCESS, GET_STOCK_RECORD_FAILURE,
  GET_SHIPPING_METHODS, GET_SHIPPING_METHODS_SUCCESS, GET_SHIPPING_METHODS_FAILURE, PLACE_ORDER,
  PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE, SEARCH_ITEM, SEARCH_ITEM_SUCCESS, SEARCH_ITEM_FAILURE,
  GET_ITEM, GET_ITEM_SUCCESS, GET_ITEM_FAILED, GET_ALL_PRODUCTS, GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE, GET_FOOTER_API_DATA, GET_FOOTER_API_DATA_SUCCESS, GET_FOOTER_API_DATA_FAILURE
} from "../constants";

export class vehicleAction {
  // Get Vehicle Make Count
  static getVehicleMakeCount() {
    return {
      type: GET_VEHICLE_MAKE_COUNT
    };
  }

  static getVehicleMakeCountSuccess(payload) {
    return {
      type: GET_VEHICLE_MAKE_COUNT_SUCCESS,
      payload
    };
  }

  static getVehicleMakeCountFailure(error) {
    return {
      type: GET_VEHICLE_MAKE_COUNT_FAILURE,
      error
    };
  }

  // Search Vehicle
  static searchVehicle(payload) {
    return {
      type: SEARCH_VEHICLE,
      payload
    };
  }

  static searchVehicleSuccess(payload) {
    return {
      type: SEARCH_VEHICLE_SUCCESS,
      payload
    };
  }

  static searchVehicleFailure(error) {
    return {
      type: SEARCH_VEHICLE_FAILURE,
      error
    };
  }

    // Search Vehicle
  static searchItem(payload) {
    return {
      type: SEARCH_ITEM,
      payload
    };
  }

  static searchItemSuccess(payload) {
    return {
      type: SEARCH_ITEM_SUCCESS,
      payload
    };
  }

  static searchItemFailure(error) {
    return {
      type: SEARCH_ITEM_FAILURE,
      error
    };
  }

  // Get Products
  static getProducts(payload) {
    return {
      type: GET_PRODUCTS,
      payload
    };
  }

  static getProductsSuccess(payload) {
    return {
      type: GET_PRODUCTS_SUCCESS,
      payload
    };
  }

  static getProductsFailure(error) {
    return {
      type: GET_PRODUCTS_FAILURE,
      error
    };
  }

  // Get Products
  static getAllProducts(payload) {
    return {
      type: GET_ALL_PRODUCTS,
      payload
    };
  }

  static getAllProductsSuccess(payload) {
    return {
      type: GET_ALL_PRODUCTS_SUCCESS,
      payload
    };
  }

  static getAllProductsFailure(error) {
    return {
      type: GET_ALL_PRODUCTS_FAILURE,
      error
    };
  }

  // Get Countries
  static getCountries() {
    return {
      type: GET_COUNTRIES
    };
  }

  static getCountriesSuccess(payload) {
    return {
      type: GET_COUNTRIES_SUCCESS,
      payload
    };
  }

  static getCountriesFailure(error) {
    return {
      type: GET_COUNTRIES_FAILURE,
      error
    };
  }

  // Get Specifications
  static getSpecifications(payload) {
    return {
      type: GET_SPECIFICATIONS,
      payload
    };
  }

  static getSpecificationsSuccess(payload) {
    return {
      type: GET_SPECIFICATIONS_SUCCESS,
      payload
    };
  }

  static getSpecificationsFailure(error) {
    return {
      type: GET_SPECIFICATIONS_FAILURE,
      error
    };
  }

  // Get Price
  static getPrice(payload) {
    return {
      type: GET_PRICE,
      payload
    };
  }

  static getPriceSuccess(payload) {
    return {
      type: GET_PRICE_SUCCESS,
      payload
    };
  }

  static getPriceFailure(error) {
    return {
      type: GET_PRICE_FAILURE,
      error
    };
  }

    // Get stock record
  static getStockRecord(payload) {
    return {
      type: GET_STOCK_RECORD,
      payload
    };
  }

  static getStockRecordSuccess(payload) {
    return {
      type: GET_STOCK_RECORD_SUCCESS,
      payload
    };
  }

  static getStockRecordFailure(error) {
    return {
      type: GET_STOCK_RECORD_FAILURE,
      error
    };
  }

  // Add Product To Basket
  static addProductToBasket(payload) {
    return {
      type: ADD_PRODUCT_TO_BASKET,
      payload
    };
  }

  static addProductToBasketSuccess(payload) {
    return {
      type: ADD_PRODUCT_TO_BASKET_SUCCESS,
      payload
    };
  }

  static addProductToBasketFailure(error) {
    return {
      type: ADD_PRODUCT_TO_BASKET_FAILURE,
      error
    };
  }

  static placeOrder(payload) {
    return {
      type: PLACE_ORDER,
      payload
    };
  }

  static placeOrderSuccess(payload) {
    return {
      type: PLACE_ORDER_SUCCESS,
      payload
    }
  }

  static placeOrderFailure(error) {
    return {
      type: PLACE_ORDER_FAILURE,
      error
    }
  }

  // // Get Best Deals
  // static getBestDeals() {
  //   return {
  //     type: GET_BEST_DEALS
  //   };
  // }
  //
  // static getBestDealsSuccess(payload) {
  //   return {
  //     type: GET_BEST_DEALS_SUCCESS,
  //     payload
  //   };
  // }
  //
  // static getBestDealsFailure(error) {
  //   return {
  //     type: GET_BEST_DEALS_FAILURE,
  //     error
  //   };
  // }

  // Get Promotional Api Data
  static getPromotionalApiData() {
    return {
      type: GET_PROMOTIONAL_API_DATA
    };
  }

  static getPromotionalApiDataSuccess(payload) {
    return {
      type: GET_PROMOTIONAL_API_DATA_SUCCESS,
      payload
    };
  }

  static getPromotionalApiDataFailure(error) {
    return {
      type: GET_PROMOTIONAL_API_DATA_FAILURE,
      error
    };
  }

  static getFooterApiData() {
    return {
      type: GET_FOOTER_API_DATA,
    };
  }

  static getFooterApiDataSuccess(payload) {
    return {
      type: GET_FOOTER_API_DATA_SUCCESS,
      payload
    };
  }

  static getFooterApiDataFailure(error) {
    return {
      type: GET_FOOTER_API_DATA_FAILURE,
      error
    }
  }
  static getVehicle(id) {
    return {
      type: GET_VEHICLE,
      payload: id
    }
  }

  static getVehicleSuccess(data) {
    return {
      type: GET_VEHICLE_SUCCESS,
      payload: data
    }
  }

  static getVehicleFailed(err) {
    return {
      type: GET_VEHICLE_FAILED,
      payload: err
    }
  }

  // Get Item API data
  static getItem(id) {
    return {
      type: GET_ITEM,
      payload: id
    }
  }

  static getItemSuccess(data) {
    return {
      type: GET_ITEM_SUCCESS,
      payload: data
    }
  }

  static getItemFailure(err) {
    return {
      type: GET_ITEM_FAILED,
      payload: err
    }
  }

  // Get shipping methods API data
  static getShippingMethods() {
    return {
      type: GET_SHIPPING_METHODS,
    }
  }

  static getShippingMethodsSuccess(payload) {
    return {
      type: GET_SHIPPING_METHODS_SUCCESS,
      payload
    };
  }

  static getShippingMethodsFailure(error) {
    return {
      type: GET_SHIPPING_METHODS_FAILURE,
      error
    };
  }
}
