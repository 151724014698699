import React from 'react';
import {Alert, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback, AvField } from 'availity-reactstrap-validation';


const CarBuyDetail  = ({
                         details,
                         onChange,
                         VisaLogo,
                         onImage,
                         onBuy,
                         handleKeyPress,
                         showAgeModal,
                         toggleAgeModal,
                         error,
                         notification
                        }) => {

  return (
    <div>
      <div>
      <section className="gen-section  filter_space">
      <div className="container mobile_padding">
        <div className="row">
        <div className="col-lg-12 col-sm-12">
          <div style={{minHeight: "25px",
            backgroundColor: "yellow",
            textAlign: "center",
            paddingTop: "5px",
            paddingBottom: "5px"}}>
            <p className="basket-item-title"
               style={{paddingTop: "2px", marginBottom: "0px", fontSize: "80%"}}>
               We only deliver within a 10 mile radius of Leeds
            </p>
          </div>
          <div className="static-info-page">
            <Modal isOpen={showAgeModal} toggle={toggleAgeModal.bind(this,false)}>
              <ModalHeader
                style={{fontWeight: "bold"}}
                toggle={toggleAgeModal.bind(this,false)}>
                Age Verification
              </ModalHeader>
              <ModalBody style={{ textAlign:'center'}}>
                I understand that I need to be 18 years old to purchase alcohol<p></p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={toggleAgeModal.bind(this,false)}>Okay</Button>
              </ModalFooter>
            </Modal>
            <div className="" style={{ textAlign: 'center' }}>
              {notification &&
              <div style={{padding: "16px 0px 8px 0px"}}>
                <Alert color={error ? "danger" : "success"}>
                  {notification}
                </Alert>
              </div>
              }
              <div className="col-lg-6 col-sm-12 offset-lg-3">
              <AvForm onValidSubmit={onBuy.bind(this,3,false)} style={{padding: '20px'}}>
                <div className="basket-page-information" style={{color: '#000000', fontSize: '14px'}}>
                  YOUR DETAILS
                </div>
                <AvGroup>
                  <Label for="firstName" className="form-input">First Name</Label>
                  <AvInput
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={onChange}
                    // value={firstN}
                    onKeyPress={handleKeyPress}
                    placeholder="Your first name"
                    // required
                    maxLength={255}
                  />
                  <AvFeedback>A first name is required to place order.</AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="lastName" className="form-input">Last Name</Label>
                  <AvInput
                    name="lastName"
                    type="text"
                    onChange={onChange}
                    placeholder="Your last name"
                    required
                    maxLength={255}
                  />
                  <AvFeedback>A surname is required to place an order</AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="userEmail" className="form-input">Email</Label>
                  <AvInput
                    name="userEmail"
                    type="email"
                    onChange={onChange}
                    required
                    placeholder="Your email address"
                    maxLength={255}
                  />
                  <AvFeedback>A valid email is required to place order.</AvFeedback>
                </AvGroup>
                <Label for="contactNumber" className="form-input">Contact Number</Label>
                <AvField class="form-input"
                  onChange={onChange}
                  name="contactNumber"
                  placeholder="Incl country and region codes"
                  type="text" validate={{
                  required: {value: true, errorMessage: 'Please enter a phone number (including country code)'},
                  pattern: {value: '^[0-9]+$', errorMessage: 'Your number must be composed only of numbers'},
                  minLength: {value: 5, errorMessage: 'Your number must be between 5 and 16 characters'},
                  maxLength: {value: 16, errorMessage: 'Your number must be between 5 and 16 characters'}
                }} />

                <div className="basket-page-information"
                     style={{color: '#000000', fontSize: '14px', marginTop: '40px'}}>
                  DELIVERY ADDRESS
                </div>
                <AvGroup>
                  <Label for="line1" className="form-input">Address Line 1</Label>
                  <AvInput
                    name="line1"
                    type="text"
                    required
                    onChange={onChange}
                    placeholder="Address Line 1"
                    maxLength={50}
                  />
                  <AvFeedback>A street name / number is required to place order.</AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="line2" className="form-input">Address Line 2</Label>
                  <AvInput
                    name="line2"
                    type="text"
                    onChange={onChange}
                    placeholder="Address Line 2"
                    maxLength={50}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="city" className="form-input">City</Label>
                  <AvInput
                    name="city"
                    type="text"
                    onChange={onChange}
                    required
                    placeholder="City"
                    maxLength={50}
                  />
                  <AvFeedback>A city name is required to place order.</AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="province" className="form-input">County</Label>
                  <AvInput
                    name="province"
                    type="text"
                    onChange={onChange}
                    placeholder="County"
                    required
                    maxLength={50}
                  />
                  <AvFeedback>A county is required to place order.</AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="country" className="form-input">Postcode</Label>
                    <AvInput
                      type="text"
                      name="postCode"
                      onChange={onChange}
                      required
                      // value={details.country}
                      maxLength={50}
                      id="postCode">
                    </AvInput>
                    <AvFeedback>A postcode is required to place order.</AvFeedback>
                </AvGroup>
                <br />
                <div className="col">
                  <Button size={"sm"} className="delete-basket-button">Next</Button>
               </div>
              </AvForm>
              </div>
            </div>
          </div>
       </div>
       </div>
      </div>
      </section>
        <hr/>
      </div>
      <br/>
     <br/>
    </div>
    );
  }


export default CarBuyDetail;