import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import { Button } from 'reactstrap';
import './BestDeals.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import LazyLoad from 'react-lazyload';
import PromoElements from "./PromoElements";
import { prodLandingData, sofaLandingData } from './../shared/prod_promo_landing_data';
import {Loading} from "./LoadingComponent";
import { accorladesData} from "../shared/promo_landing_data"
import AccoladesPromoElements from "./AccoladesPromoElements";


class BestDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      isLoading: true,
      errMess: null,
      dealers: [],
      recommended: [],
      parts: [],
      selectedPriceTab: 1,
      selectedSearchedCarTab: 1
    };
  }

  componentDidMount() {
    this.props.getPromotionalApiData && this.props.getPromotionalApiData();
    this.props.getFooterApiData && this.props.getFooterApiData();

    if (this.props && this.props.location.state && this.props.location.state.pathname && this.props.location.state.pathname === "/login") {
      document.body.scrollTop = 0
      window.scrollTo(0,0);
    }
  }


  render() {
    let {
      getPromotionalApiDataSuccess,
      getPromotionalApiDataError,
      getPromotionalApiDataLoader,
      getFooterApiDataSuccess,
      getFooterApiDataError,
      getFooterApiDataLoader
    } = this.props;

    let { selectedPriceTab, selectedSearchedCarTab } = this.state;
    let trustedCars = [];
    let mostSearched = [];
    let commercialVehicles = [];
    let drinkAwareApiImg = ''
    if (getFooterApiDataSuccess) {
      drinkAwareApiImg = getFooterApiDataSuccess.results.drink_aware
    }

    if (getPromotionalApiDataSuccess) {
      const {
        trusted_cars,
        most_searched,
        commercial_vehicles,
      } = getPromotionalApiDataSuccess;

      if (selectedPriceTab === 0) {
        trustedCars = trusted_cars.low_range;
      } else if (selectedPriceTab === 1) {
        trustedCars = trusted_cars.mid_range;
      } else if (selectedPriceTab === 2) {
        trustedCars = trusted_cars.upper_range;
      }

      if (selectedSearchedCarTab === 0) {
        mostSearched = most_searched.hatch_back;
      } else if (selectedSearchedCarTab === 1) {
        mostSearched = most_searched.sedan;
      } else if (selectedSearchedCarTab === 2) {
        mostSearched = most_searched.suv;
      }

      commercialVehicles = commercial_vehicles;

    }

      return (
        <div className="dealer">
          {/* Best Deals Start */}
          <div
            className="best-deal"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingTop: '8px',
              paddingBottom: '64px'
            }}
          >
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h3 className="headings">Shopping made comfortable</h3>
              </div>
            </div>
            <div className="container" style={{ paddingTop: '0px'}}>
              <div className="row">
                {accorladesData && accorladesData.map((d, i) => {
                  return (
                    <LazyLoad height={20} offset={100} once >
                      <AccoladesPromoElements d={d} i={i}>
                      </AccoladesPromoElements>
                    </LazyLoad>
                  );
                })}
              </div>
              <div className="row">
                {prodLandingData && prodLandingData.map((d, i) => {
                   return (
                     <LazyLoad height={20} offset={100} once >
                       <PromoElements d={d} i={i}>
                       </PromoElements>
                     </LazyLoad>
                   );
                 })}
              </div>

              {/* Banners */}
              {/* Recommended For You Ends */}
              <div className="latest-arrival">
                <div className="container mt-4" style={{ height: 'auto', paddingLeft: '-15px' }}>
                  <div className="row">
                    <LazyLoad height={200}>
                      <div className="promo-banners col-sm-12 col-lg-6 mt-4" style={{ paddingBottom: '5px', paddingLeft: '5px', paddingRight: '5px'}}>
                        <div className="car-accessories">
                          <a href="/profile">
                            <div className="col-lg-12" >
                              <h6 className="banner-content-header">Best prices guaranteed</h6>
                              <h3 className="banner-content-body">WINTER SALE</h3>
                              <Button className="shop-now" id="shop-now">
                                Shop Now
                              </Button>
                            </div>
                          </a>
                        </div>
                      </div>
                    </LazyLoad>
                    <LazyLoad height={200}>
                      <div className="promo-banners col-sm-12 col-lg-6 mt-4" style={{paddingLeft: '5px', paddingRight: '5px'}}>
                        <div className="electronics">
                          <a href="/auto-parts">
                            <div className="col-lg-12">
                              <h6 className="banner-content-header">No Deposit</h6>
                              <h3 className="banner-content-body">INTEREST FREE CREDIT</h3>
                              <Button className="shop-now" id="shop-now">
                                Shop Now
                              </Button>
                            </div>
                          </a>
                        </div>
                      </div>
                    </LazyLoad>
                  </div>
                </div>
              </div>

              {/* Sofa sections */}
              <div className="row" style={{paddingTop: "48px"}}>
                {sofaLandingData && sofaLandingData.map((d, i) => {
                  return (
                    <LazyLoad height={20} offset={100} once >
                      <PromoElements d={d} i={i}>
                      </PromoElements>
                    </LazyLoad>
                  );
                })}
              </div>
            </div>
            <hr/>
            <div className="container">
              <div className="row">
                {getFooterApiDataLoader
                  ?
                  <div style={{margin: "0 auto"}}>
                    <Loading />
                  </div>:
                    <img className="card-img-top"
                         style={{maxHeight: "100px"}}
                         src={drinkAwareApiImg} alt="Drink aware" />
                }

              </div>
            </div>
          </div>

        </div>
      );
  }
}

export default withRouter(BestDeals);
