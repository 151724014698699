// Register User
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

// Api Token Auth
export const API_TOKEN_AUTH = "API_TOKEN_AUTH";
export const API_TOKEN_AUTH_SUCCESS = "API_TOKEN_AUTH_SUCCESS";
export const API_TOKEN_AUTH_FAILURE = "API_TOKEN_AUTH_FAILURE";


// Api Token Auth
export const API_TOKEN_DESTROY = "API_TOKEN_DESTROY";
export const API_TOKEN_DESTROY_SUCCESS = "API_TOKEN_DESTROY_SUCCESS";
export const API_TOKEN_DESTROY_FAILURE = "API_TOKEN_DESTROY_FAILURE";

// Phone validation
export const API_PHONE_VALIDATION = "API_PHONE_VALIDATION";
export const API_PHONE_VALIDATION_SUCCESS = "API_PHONE_VALIDATION_SUCCESS";
export const API_PHONE_VALIDATION_FAILURE = "API_PHONE_VALIDATION_FAILURE";

// Phone validation confirm
export const API_PHONE_VALIDATION_CONFIRM = "API_PHONE_VALIDATION_CONFIRM";
export const API_PHONE_VALIDATION_CONFIRM_SUCCESS = "API_PHONE_VALIDATION_CONFIRM_SUCCESS";
export const API_PHONE_VALIDATION_CONFIRM_FAILURE = "API_PHONE_VALIDATION_CONFIRM_FAILURE";

// Social Api Auth
export const SOCIAL_API_TOKEN = "SOCIAL_API_TOKEN";

// Password Reset
export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

// Password Reset Confirm
export const PASSWORD_RESET_CONFIRM = "PASSWORD_RESET_CONFIRM";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAILURE = "PASSWORD_RESET_CONFIRM_FAILURE";

export const ACTIVATE_ACCOUNT_CONFIRM = "ACTIVATE_ACCOUNT_CONFIRM";
export const ACTIVATE_ACCOUNT_CONFIRM_SUCCESS = "ACTIVATE_ACCOUNT_CONFIRM_SUCCESS";
export const ACTIVATE_ACCOUNT_CONFIRM_FAILURE = "ACTIVATE_ACCOUNT_CONFIRM_FAILURE"

export const RESEND_ACTIVATION_LINK = "RESEND_ACTIVATION_LINK";
export const RESEND_ACTIVATION_LINK_SUCCESS = "RESEND_ACTIVATION_LINK_SUCCESS";
export const RESEND_ACTIVATION_LINK_FAILURE = "RESEND_ACTIVATION_LINK_FAILURE";

// Get User Profile
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const UPGRADE_TO_AGENT_ACCOUNT = "UPGRADE_TO_AGENT_ACCOUNT";
export const UPGRADE_TO_AGENT_ACCOUNT_SUCCESS = "UPGRADE_TO_AGENT_ACCOUNT_SUCCESS"
export const UPGRADE_TO_AGENT_ACCOUNT_FAILURE = "UPGRADE_TO_AGENT_ACCOUNT_FAILURE"

// Get Agent Profile
export const GET_AGENT_PROFILE = "GET_AGENT_PROFILE";
export const GET_AGENT_PROFILE_SUCCESS = "GET_AGENT_PROFILE_SUCCESS";
export const GET_AGENT_PROFILE_FAILURE = "GET_AGENT_PROFILE_FAILURE";

// Vehicle Make Count
export const GET_VEHICLE_MAKE_COUNT = "GET_VEHICLE_MAKE_COUNT";
export const GET_VEHICLE_MAKE_COUNT_SUCCESS = "GET_VEHICLE_MAKE_COUNT_SUCCESS";
export const GET_VEHICLE_MAKE_COUNT_FAILURE = "GET_VEHICLE_MAKE_COUNT_FAILURE";

// Search Vehicle
export const SEARCH_VEHICLE = "SEARCH_VEHICLE";
export const SEARCH_VEHICLE_SUCCESS = "SEARCH_VEHICLE_SUCCESS";
export const SEARCH_VEHICLE_FAILURE = "SEARCH_VEHICLE_FAILURE";

// Get Products
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

// Get All Products
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";


// Get Countries
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

// Get Specificaions
export const GET_SPECIFICATIONS = "GET_SPECIFICATIONS";
export const GET_SPECIFICATIONS_SUCCESS = "GET_SPECIFICATIONS_SUCCESS";
export const GET_SPECIFICATIONS_FAILURE = "GET_SPECIFICATIONS_FAILURE";

// Get Price
export const GET_PRICE = "GET_PRICE";
export const GET_PRICE_SUCCESS = "GET_PRICE_SUCCESS";
export const GET_PRICE_FAILURE = "GET_PRICE_FAILURE";

// Get Price
export const GET_STOCK_RECORD = "GET_STOCK_RECORD";
export const GET_STOCK_RECORD_SUCCESS = "GET_STOCK_RECORD_SUCCESS";
export const GET_STOCK_RECORD_FAILURE = "GET_STOCK_RECORD_FAILURE";

// Add Product To Basket
export const ADD_PRODUCT_TO_BASKET = "ADD_PRODUCT_TO_BASKET";
export const ADD_PRODUCT_TO_BASKET_SUCCESS = "ADD_PRODUCT_TO_BASKET_SUCCESS";
export const ADD_PRODUCT_TO_BASKET_FAILURE = "ADD_PRODUCT_TO_BASKET_FAILURE";

// Place Order
export const PLACE_ORDER = "PLACE_ORDER";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";

// Get User Basket
export const GET_USER_BASKET = "GET_USER_BASKET";
export const GET_USER_BASKET_SUCCESS = "GET_USER_BASKET_SUCCESS";
export const GET_USER_BASKET_FAILURE = "GET_USER_BASKET_FAILURE";

// Get Shipping Quote
export const GET_SHIPPING_QUOTE = "GET_SHIPPING_QUOTE";
export const GET_SHIPPING_QUOTE_SUCCESS = "GET_SHIPPING_QUOTE_SUCCESS";
export const GET_SHIPPING_QUOTE_FAILURE = "GET_SHIPPING_QUOTE_FAILURE";

// Download File
export const GET_DOWNLOAD_FILE = "GET_DOWNLOAD_FILE";
export const GET_DOWNLOAD_FILE_SUCCESS = "GET_DOWNLOAD_FILE_SUCCESS";
export const GET_DOWNLOAD_FILE_FAILURE = "GET_DOWNLOAD_FILE_FAILURE";

// Update Training Progress
export const UPDATE_TRAINING_PROGRESS = "UPDATE_TRAINING_PROGRESS";
export const UPDATE_TRAINING_PROGRESS_SUCCESS = "UPDATE_TRAINING_PROGRESS_SUCCESS";
export const UPDATE_TRAINING_PROGRESS_FAILURE = "UPDATE_TRAINING_PROGRESS_FAILURE";

// DISCOUNT CODE
export const DISCOUNT_CODE_APPLICATION = "DISCOUNT_CODE_APPLICATION"
export const DISCOUNT_CODE_APPLICATION_SUCCESS = "DISCOUNT_CODE_APPLICATION_SUCCESS"
export const DISCOUNT_CODE_APPLICATION_FAILURE = "DISCOUNT_CODE_APPLICATION_FAILURE"

// Get User Order List

export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const GET_USER_ORDERS_SUCCESS = "GET_USER_ORDERS_SUCCESS";
export const GET_USER_ORDERS_FAILURE = "GET_USER_ORDERS_FAILURE";

// // Get Best Deals
// export const GET_BEST_DEALS = "GET_BEST_DEALS";
// export const GET_BEST_DEALS_SUCCESS = "GET_BEST_DEALS_SUCCESS";
// export const GET_BEST_DEALS_FAILURE = "GET_BEST_DEALS_FAILURE";

// Get Promotional Api Data
export const GET_PROMOTIONAL_API_DATA = "GET_PROMOTIONAL_API_DATA";
export const GET_PROMOTIONAL_API_DATA_SUCCESS =
  "GET_PROMOTIONAL_API_DATA_SUCCESS";
export const GET_PROMOTIONAL_API_DATA_FAILURE =
  "GET_PROMOTIONAL_API_DATA_FAILURE";

// Get Footer API Data
export const GET_FOOTER_API_DATA = "GET_FOOTER_API_DATA";
export const GET_FOOTER_API_DATA_SUCCESS = "GET_FOOTER_API_DATA_SUCCESS";
export const GET_FOOTER_API_DATA_FAILURE = "GET_FOOTER_API_DATA_FAILURE";

// Filter Collapse Action Type
export const FILTER_COLLAPSE = "FILTER_COLLAPSE";

// Cart Management
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const CLEAR_CART = "CLEAR_CART"

export const MERGE_CART = "MERGE_CART";
export const MERGE_CART_SUCCESS = "MERGE_CART_SUCCESS";
export const MERGE_CART_FAILURE = "MERGE_CART_FAILURE";

// API Basket Management
export const DELETE_BASKET_ITEM = "DELETE_BASKET_ITEM";
export const DELETE_BASKET_ITEM_SUCCESS = "DELETE_BASKET_ITEM_SUCCESS";
export const DELETE_BASKET_ITEM_FAILURE = "DELETE_BASKET_ITEM_FAILURE";

//SideMenu Action Type
export const SIDEMENU_TOGGLE = "SIDEMENU_TOGGLE";


export const GET_VEHICLE = 'GET_VEHICLE';
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS';
export const GET_VEHICLE_FAILED = 'GET_VEHICLE_FAILED';

// Get Item Details
export const GET_ITEM = 'GET_ITEM';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_FAILED = 'GET_ITEM_FAILED';

// GET SHIPPING METHODS
export const GET_SHIPPING_METHODS = "GET_SHIPPING_METHODS";
export const GET_SHIPPING_METHODS_SUCCESS = "GET_SHIPPING_METHODS_SUCCESS";
export const GET_SHIPPING_METHODS_FAILURE = "GET_SHIPPING_METHODS_FAILURE";

// SEARCH ITEM
export const SEARCH_ITEM = "SEARCH_ITEM";
export const SEARCH_ITEM_SUCCESS = "SEARCH_ITEM_SUCCESS";
export const SEARCH_ITEM_FAILURE = "SEARCH_ITEM_FAILURE";

// SEARCH
export const SORT_BY_ALPHABET = "SORT_BY_ALPHABET";
export const SORT_BY_PRICE = "SORT_BY_PRICE";
export const FILTER_BY_PRICE = "FILTER_BY_PRICE";
export const FILTER_BY_VALUE = "FILTER_BY_VALUE";
export const FILTER_BY_CAT = "FILTER_BY_CAT"
export const FILTER_BY_CLASS = "FILTER_BY_CLASS"

// GET BASKET METADATA